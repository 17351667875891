import $ from "jquery"
window.$ = window.jQuery = $;

$.ajaxSetup({
    beforeSend: function(xhr) {
        xhr.setRequestHeader(
            "X-CSRF-TOKEN",
            $('meta[name="kotori-key"]').attr("content")
        );
    },
});

$(document).ready(() => {
    $(".verify-code-input input").keydown(function(e) {
        for (let i = 0; i < 6; i++) {
            if ($(".verify-code-input input").eq(i).val() === "") {
                $(".verify-code-input input").eq(i).focus()
                break;
            }
        }
    });


    $(".verify-code-input input").keyup(function(e) {
        if (e.keyCode === 8) {
            $(this).prev('input').val('').focus()
        } else {
            if($(this).index()<6) {
                $(this).next('input').focus();
            }
        }
    })

})
